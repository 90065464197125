interface Activity {
  activityType: string;
  parameters: [
    {
      parameter: string;
      value: number;
      unit: string;
    }
  ];
  duration: number;
  date: string;
}

interface ParsedData {
  [key: string]: { [key: string]: { unit: string; value: number } };
}

export const parseActivitiesData = (data: Activity[]): ParsedData => {
  const parsedData: ParsedData = {};

  data.forEach((activity: Activity) => {
    const activityType: string = activity.activityType;

    if (!parsedData[activityType]) {
      parsedData[activityType] = {};
    }

    if (!parsedData[activityType]["duration"]) {
      parsedData[activityType]["duration"] = { unit: "s", value: 0 };
    }

    parsedData[activityType]["duration"].value += activity.duration;

    activity.parameters.forEach((parameter) => {
      const unit: string = parameter.unit;
      const value: number = parameter.value;
      const paramKey: string = parameter.parameter;

      if (!parsedData[activityType][paramKey]) {
        parsedData[activityType][paramKey] = { unit: "", value: 0 };
      }
      parsedData[activityType][paramKey].unit = unit;
      parsedData[activityType][paramKey].value =
        +(parsedData[activityType][paramKey].value || 0) + +value;
    });
  });

  return parsedData;
};
