import { PieChart, Pie, Cell, Legend, Tooltip, Label } from "recharts";
import { useState, useEffect, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ActivitiesDonutChart(props: any) {
  const { t } = useTranslation();
  // sum property
  const [sum, setSum] = useState(0);
  const [data, setData] = useState<{ name: string; value: number }[]>([
    {
      name: "Walking",
      value: 0,
    },
  ]);
  useEffect(() => {
    const parsedMinutesData = Object.keys(props.chartProps).map(
      (key: any, index: any) => {
        const activity = props.chartProps[index];
        if (activity["unit"] === "s") {
          activity["value"] = Math.floor(activity["value"] / 60);
          activity["unit"] = "m";
        }
        return activity;
      }
    );

    const sum = Object.keys(props.chartProps).reduce(
      (acc: any, curr: any, index) => {
        const activity = props.chartProps[index];
        acc += activity["value"];
        return acc;
      },
      0
    );

    setData(parsedMinutesData);
    setSum(sum);
  }, [props.chartProps]);

  const COLORS = [
    "#b050e8ff",
    "#fb6282ff",
    "#f6956bff",
    "#3343d6ff",
    "#3343d6ff",
  ];

  return (
    <Row>
      <Col className="d-flex align-items-center justify-content-center">
        {/* Activities Donut Chart */}
        <PieChart width={400} height={260}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={0}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              value={sum.toString()}
              position="inside"
              className="sum-label"
              content={(props) => {
                const containerWidth = 400; // Container width
                const containerHeight = 260; // Container Height
                const cx = containerWidth / 2;
                const cy = containerHeight / 2;

                return (
                  <Fragment>
                    <text
                      x={cx}
                      y={cy}
                      dy={-10}
                      textAnchor="middle"
                      className="sum-label"
                      style={{ fontSize: "1.6rem" }}
                    >
                      {sum.toString()}
                    </text>
                    <text
                      x={cx}
                      y={cy}
                      dy={10}
                      textAnchor="middle"
                      className="sum-label"
                    >
                      {t("activitiesChart.minutes")}
                    </text>
                  </Fragment>
                );
              }}
            />
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Col>
    </Row>
  );
}

export default ActivitiesDonutChart;
