import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from "recharts";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type LineChartType = {
  axis: {
    xLabel: string;
    yLabel: string;
  };
  data: {
    x: string;
    stepsThisWeek: number;
    stepsLastWeek: number;
    minRecommendedValue: number;
  }[];
};

type StepsLineChartProps = {
  lineChart: LineChartType;
};

function StepsLineChart(props: StepsLineChartProps) {
  const { t } = useTranslation();

  const [graphData, setGraphData] = useState<LineChartType>({
    axis: {
      xLabel: "",
      yLabel: "",
    },
    data: [],
  });

  useEffect(() => {
    setGraphData(props.lineChart);
  }, [props.lineChart]);

  return (
    <Row>
      <Col sm={12}>
        {graphData && graphData.data && graphData.data.length > 0 ? (
          <ResponsiveContainer height={400}>
            <ComposedChart data={graphData["data"]}>
              <Legend verticalAlign="top" height={50} />
              <XAxis dataKey="x" name={graphData["axis"]["xLabel"]} />
              <YAxis name="Y" />
              <Line
                type="monotone"
                dataKey="stepsThisWeek"
                fill="#3343d6ff"
                stroke="#3343d6ff"
                strokeWidth={2}
                name={t("stepsChart.stepsThisWeek")}
                fontSize={12}
              />
              <Area
                type="monotone"
                dataKey="stepsLastWeek"
                fill="#f6956b33"
                stroke="#f6956bff"
                name={t("stepsChart.stepsLastWeek")}
              />
              <Line
                type="monotone"
                strokeWidth={2}
                dataKey="minRecommendedValue"
                name={t("stepsChart.minRecommendedValue")}
                stroke="#ff6666"
              ></Line>
              <Tooltip />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          "No data"
        )}
      </Col>
    </Row>
  );
}

export default StepsLineChart;
