import { Fragment, useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StarsChart from "../Components/StarsChart";
import StepsLineChart, { LineChartType } from "../Components/StepsLineChart";
import Loading from "../Components/Loading";
import { useParams } from "react-router-dom";
import Api from "../Services/Api";
import { useTranslation } from "react-i18next";
import { ParseStepsData } from "../Helpers/ParseStepsData";

enum RequestStatus {
  pending,
  requesting,
  error,
  success,
}

type StepChartProps = {
  lineChart: LineChartType;
  starsChart: {
    stars: number;
    average: number;
    title: string;
  };
};

const StepsChart = () => {
  const { t } = useTranslation();
  const { dynamicId } = useParams();

  const [data, setData] = useState<StepChartProps | undefined>(undefined);

  const [status, setStatus] = useState<RequestStatus>(RequestStatus.pending);

  const handleData = useCallback(
    (dynamicId: string) => {
      setStatus(RequestStatus.requesting);
      const api = new Api();
      api
        .getResource(dynamicId)
        .then(async (response) => {
          setStatus(RequestStatus.success);
          if (response !== undefined) {
            const parsedData = await ParseStepsData(response, t);
            setData(parsedData);
          }
        })
        .catch((error) => {
          setStatus(RequestStatus.error);
        });
    },
    [t]
  );

  useEffect(() => {
    if (dynamicId !== undefined && status === RequestStatus.pending) {
      handleData(dynamicId);
    }
  }, [status, dynamicId, handleData]);

  useEffect(() => {
    window.rudderanalytics.page("Steps Chart");
  }, []);

  return (
    <Fragment>
      <Container className="mt-4">
        {status === RequestStatus.requesting ? (
          <Loading />
        ) : data === undefined ? (
          <div></div>
        ) : (
          <Fragment>
            <StarsChart starsChart={data.starsChart} />
            <br />
            <StepsLineChart lineChart={data.lineChart} />
            <br />
            <Row>
              <Col
                xl={{ span: 4, offset: 4 }}
                lg={{ span: 6, offset: 3 }}
                md={{ span: 8, offset: 2 }}
                xs={{ span: 11, offset: 1 }}
              >
                <div className="text-left" style={{ fontSize: "12px" }}>
                  {t("stepsChart.legend1")}
                </div>
                <div className="text-left" style={{ fontSize: "12px" }}>
                  {t("stepsChart.legend2")}
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default StepsChart;
