import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

type StarsChartProps = {
  starsChart: {
    stars: number;
    average: number;
    title: string;
  };
};

function StarsChart(props: StarsChartProps) {
  const { t } = useTranslation();

  const [stars, setStars] = useState([
    <FontAwesomeIcon key={`empty-star-1`} icon={solidStar} />,
    <FontAwesomeIcon key={`empty-star-2`} icon={solidStar} />,
    <FontAwesomeIcon key={`empty-star-3`} icon={solidStar} />,
    <FontAwesomeIcon key={`empty-star-4`} icon={solidStar} />,
  ]);

  const [title, setTitle] = useState("Loading...");

  const getStarsRender = (stars: number) => {
    const starsArray = [];
    for (let i = 0; i < stars; i++) {
      starsArray.push(
        <FontAwesomeIcon
          key={`star-${i}`}
          icon={solidStar}
          style={{ color: "#3343d6ff", fontSize: "2rem" }}
        />
      );
    }
    for (let i = 0; i < 4 - stars; i++) {
      starsArray.push(
        <FontAwesomeIcon
          key={`empty-star-${i}`}
          icon={regularStar}
          style={{ color: "#3343d6ff", fontSize: "2rem" }}
        />
      );
    }
    setStars(starsArray);
  };

  useEffect(() => {
    getStarsRender(props.starsChart.stars);
    const title = getStarsTitle(props.starsChart.stars);
    setTitle(t(`stepsChart.starsChartStatus${title}`));
  }, [props.starsChart, t]);

  return (
    <Row style={{ textAlign: "center", alignItems: "center" }}>
      <Col style={{ justifyContent: "center", alignItems: "center" }}>
        <h3 className="mb-3">{t("stepsChart.starsChartTitle")}</h3>
        {stars}
        <h2
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4px",
            fontWeight: 300,
          }}
        >
          {title}
        </h2>
      </Col>
    </Row>
  );
}

export default StarsChart;

function getStarsTitle(stars: number) {
  if (stars === 1) return "Sedentary";
  if (stars === 2) return "Low";
  if (stars === 3) return "Moderate";
  if (stars === 4) return "High";
  return "";
}
