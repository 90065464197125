import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loading from "../Components/Loading";
import Api from "../Services/Api";

const Start = () => {
  const { publicKey, customerId } = useParams();

  const [storedCredentials, setStoredCredentials] = useState(false);
  const [me, setMe] = useState<boolean>(false);

  useEffect(() => {
    if (!storedCredentials && publicKey && customerId) {
      localStorage.setItem("publicKey", publicKey);
      localStorage.setItem("externalId", customerId);
      setStoredCredentials(true);

      const api = new Api();
      const me = api.getClient();
      me.then((result) => {
        localStorage.setItem("customerName", result.name);
        localStorage.setItem("customerId", result.id);
        localStorage.setItem("externalId", result.externalId);

        window.rudderanalytics.identify({
          userId: result.externalId,
          traits: {
            name: result.name,
            type: result.type,
          },
        });

        setMe(true);
      }).catch((ex) => {
        console.log(ex);
      });
    }
  }, [storedCredentials, publicKey, customerId]);

  return me && storedCredentials ? (
    <Navigate to={"/home"} replace={true} />
  ) : (
    <Loading />
  );
};

export default Start;
