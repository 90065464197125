import { Col, ProgressBar, Row } from "react-bootstrap";

const ActivitiesPercent = (props: any) => {
  const recommended = 180;
  const sum = Object.keys(props.chartProps).reduce(
    (acc: any, curr: any, index) => {
      const activity = props.chartProps[index];

      if (activity["unit"] === "s") {
        activity["value"] = Math.floor(activity["value"] / 60);
        activity["unit"] = "m";
      }

      acc += activity["value"];
      return acc;
    },
    0
  );

  const percent = Math.floor((sum / recommended) * 100);
  const value = percent > 100 ? 100 : percent;

  return (
    <Row className="mt-4">
      <Col
        lg={{ offset: 4, span: 4 }}
        md={{ offset: 3, span: 6 }}
        sm={{ offset: 2, span: 8 }}
      >
        <ProgressBar>
          <ProgressBar variant="success" now={value} key={1} />
          <ProgressBar
            style={{ background: "#CDCDCD" }}
            now={100 - value}
            key={2}
          />
        </ProgressBar>
        <h2
          className="text-center"
          style={{
            marginTop: "4px",
            fontWeight: 300,
          }}
        >
          {value}%
        </h2>
      </Col>
    </Row>
  );
};

export default ActivitiesPercent;
