import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

const Loading = () => {
  return (
    <DoubleBubble
      text={"Loading..."}
      center={true}
      width={"100px"}
      height={"100px"}
    />
  );
};

export default Loading;
