import { PieChart, Pie, Cell, Legend, Tooltip, Label } from "recharts";
import { useState, useEffect, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { SleepChartProps } from "../Types/SleepChartProps";
import { SleepChartDataPoint } from "../Types/SleepChartDatapoint";

function minutesToHHMM(minutes: number) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = Math.floor(minutes) % 60;

  var hoursString = hours.toString().padStart(2, "0");
  var minutesString = remainingMinutes.toString().padStart(2, "0");
  return hoursString + ":" + minutesString;
}

function ActivitiesDonutChart(props: SleepChartProps) {
  const [graphData, setGraphData] = useState<SleepChartDataPoint[]>([]);
  const [meanSleepDuration, setMeanSleepDuration] = useState<String>("HH:mm");
  useEffect(() => {
    setGraphData(props.sleepChartDataPoints);
    setMeanSleepDuration(minutesToHHMM(props.meanSleepDuration.valueOf()));
  }, [props.sleepChartDataPoints, props.meanSleepDuration]);

  const COLORS = [
    "#b050e8ff",
    "#fb6282ff",
    "#f6956bff",
    "#3343d6ff",
    "#3343d6ff",
  ];

  return (
    <Row>
      <Col className="d-flex align-items-center justify-content-center">
        {/* Activities Donut Chart */}
        <PieChart width={400} height={300}>
          <Pie
            data={graphData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={0}
          >
            {graphData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              value={meanSleepDuration.toString()}
              position="center"
              className="sum-label"
              content={(props) => {
                const containerWidth = 400; // Container width
                const containerHeight = 300; // Container Height
                const cx = containerWidth / 2;
                const cy = containerHeight / 2;

                return (
                  <Fragment>
                    <text
                      x={cx}
                      y={cy}
                      dy={-20}
                      textAnchor="middle"
                      className="sum-label"
                      style={{ fontSize: "1.4rem" }}
                    >
                      {meanSleepDuration}
                    </text>
                    <text
                      x={cx}
                      y={cy}
                      dy={0}
                      textAnchor="middle"
                      className="sum-label"
                    >
                      hours
                    </text>
                  </Fragment>
                );
              }}
            />
          </Pie>
          <Tooltip
            formatter={(value, name, props) =>
              `${value} day${value !== 1 ? "s" : ""}`
            }
          />
          <Legend />
        </PieChart>
      </Col>
    </Row>
  );
}

export default ActivitiesDonutChart;
