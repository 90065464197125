import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import LogoSvg from "../Images/logo_vivanta.svg";
import { useEffect, useState } from "react";

const Navigation = () => {
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    setClientName(localStorage.getItem("customerName") || "");
  }, []);

  return (
    <Navbar bg="vivanta" expand="lg">
      <Container>
        <Link to="/" className="text-white">
          <img
            src={LogoSvg}
            height={30}
            alt="Vivanta"
            className="d-inline-block align-top vivanta-header-logo"
          />{" "}
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav ml-4">
          <Nav className="me-auto">
            <Link to={"/"} className="nav-link">
              Home
            </Link>
          </Nav>
          <Nav>
            <NavDropdown
              title={`Welcome, ${clientName}`}
              id="basic-nav-dropdown"
            >
              <Link to={"/profile"} className="dropdown-item">
                Profile
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
