import { Fragment, useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loading from "../Components/Loading";
import { useParams } from "react-router-dom";
import ActivitiesDonutChart from "../Components/ActivitiesDonutChart";
import { useTranslation } from "react-i18next";
import { parseActivitiesData } from "../Helpers/ParseActivitiesData";
import ActivitiesPercent from "../Components/ActivitiesPercent";

function ActivitiesChart() {
  const { dynamicId } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [chartProps, setChartProps] = useState<
    { name: string; value: number; unit: string }[]
  >([
    {
      name: "Walking",
      value: 0,
      unit: "s",
    },
  ]);

  useEffect(() => {
    window.rudderanalytics.page("Activity Chart");
  }, []);

  const fetchData = useCallback(async (dynamicId: string) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/graphs/${dynamicId}`
      );
      const responseActivity = parseActivitiesData(response.data);
      let chartData = Object.keys(responseActivity).map(
        (activityType: string) => {
          if (!responseActivity?.[activityType]?.["duration"])
            return {
              name: capitalizeFirstLetter(activityType),
              value: 0,
              unit: "s",
            };
          return {
            name: capitalizeFirstLetter(activityType.toLowerCase()),
            value: responseActivity[activityType]["duration"]["value"],
            unit: responseActivity[activityType]["duration"]["unit"],
          };
        }
      );

      setChartProps(chartData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (dynamicId !== undefined) {
      fetchData(dynamicId);
    }
  }, [dynamicId, fetchData]);

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Fragment>
      <Container className="mt-4">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <h3 className="text-center">{t("activitiesChart.title")}</h3>
            <ActivitiesPercent chartProps={chartProps} />
            <br />
            <br />
            <h3 className="text-center">{t("activitiesChart.title2")}</h3>
            <ActivitiesDonutChart chartProps={chartProps} />
            <br />
            <br />
            <Row>
              <Col
                xl={{ span: 4, offset: 4 }}
                lg={{ span: 6, offset: 3 }}
                md={{ span: 8, offset: 2 }}
                xs={{ span: 10, offset: 1 }}
              >
                <div className="text-left mb-2" style={{ fontSize: "12px" }}>
                  {t("activitiesChart.legend1")}
                </div>
                <div className="text-left mb-2" style={{ fontSize: "12px" }}>
                  {t("activitiesChart.legend2")}
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
}

export default ActivitiesChart;
