import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Api from '../Services/Api';
import { useTranslation } from "react-i18next";
import Loading from './Loading';

const DEFAULT_PAGE_SIZE = 50;

function WebhookTests() {
  const { t } = useTranslation();
  const [webhookResponse, setwebhookResponse] = useState<any>(undefined);
  const [webhookTypeDisplaying, setWebhookTypeDisplaying] = useState<string>("");
  const availableWebhooks = [
        {
            url: 'clients/users-webhook/test',
            translation:'webhooks.webhookType.users'
        },
        {
            url: 'clients/parameters-webhook/test',
            translation:'webhooks.webhookType.parameters'
        },
        {
            url: 'clients/activities-webhook/test',
            translation:'webhooks.webhookType.activities'
        }
    ]

  const webhooksButtons = availableWebhooks.map((webhook) => {
    return <button onClick={() => testWebhook(webhook.url, webhook.translation)}>
        {t(webhook.translation)}
    </button>
  });

  const printWebhookResponse = (response: any, webhookType: string) => {
    setwebhookResponse(response.data);
    setWebhookTypeDisplaying(webhookType);
  }

  const testWebhook = async (payload: string, translation: string) => {
    await new Api().webhookTest(payload).then((response) => {
        printWebhookResponse(response, t(translation));

    }).catch((error) => {
        printWebhookResponse(error.response, t(translation));
    });
  }

  useEffect(() => {
  }, []);

  return (
    <div>
        <div><h2>{t('webhooks.webhookTests')}</h2></div>
        <div>
            {webhooksButtons}
        </div>
        <div>
            {webhookResponse && <div>
                <div><h3>{webhookTypeDisplaying}</h3></div>
                <div><pre>{JSON.stringify(webhookResponse, null, 2) }</pre></div>
            </div>}
        </div>
    </div>
  );
}

export default WebhookTests;
