import axios from "axios";
import dayjs from "dayjs";

class Api {
  url = process.env.REACT_APP_API_URL;

  async getClient(): Promise<any> {
    const headers = this._getHeaders();
    try {
      const result = await axios.get(`${this.url}/clients/me`, {
        headers: { ...headers },
      });
      return result.data;
    } catch (ex) {
      return undefined;
    }
  }

  async getWebhookLogs(page: number, pageSize: number): Promise<any> {
    const headers = this._getHeaders();
    try {
      const result = await axios.post(`${this.url}/clients/webhook-logs`, {
        page,
        pageSize,
      },{
        headers: { ...headers },
      });
      return result.data;
    } catch (ex) {
      return undefined;
    }
  }

  async getUsers(): Promise<any> {
    const headers = this._getHeaders();
    try {
      const result = await axios.get(`${this.url}/users`, {
        headers: { ...headers },
      });
      return result.data;
    } catch (ex) {
      return undefined;
    }
  }

  async getResource(resourceId: string): Promise<any> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/graphs/${resourceId}`
      );

      return response.data;
    } catch (ex) {
      return undefined;
    }
  }

  async getAggregatedData(parameterCatalogIds: number[]): Promise<any> {
    const headers = this._getHeaders();
    console.log('here', headers)
    const today = new Date();
    const oneWeekAgo = dayjs(today).subtract(14, "day").toDate();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/aggregated-parameters`, {
          "startDate": oneWeekAgo.toISOString().split('T')[0],
          "endDate": today.toISOString().split('T')[0],
          "companyId": null,
          "parameterCatalogIds": parameterCatalogIds,
          "units": "vivanta_standard"
      }, {headers}
      );

      return response.data;
    } catch (ex) {
      return undefined;
    }
  }

  _getHeaders(): any {
    return {
      "x-api-key": localStorage.getItem("publicKey"),
      "x-customer": localStorage.getItem("externalId"),
    };
  }

  async webhookTest(payload: string): Promise<any> {
    const headers = this._getHeaders();
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/${payload}`,
      {
        headers
      }
    );
  }
}

export default Api;
